import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import listStyles from "../css/blog.module.css"
import Post from "../components/Blog/Post"
import Title from "../components/Title"
import StyledHero from "../components/StyledHero"
import SEO from "../components/SEO"

const PostList = (props) => {
  const { currentPage, numPages } = props.pageContext
  const { data } = props

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? `/blogs/` : `/blogs/${currentPage - 1}`
  const nextPage = `/blogs/${currentPage + 1}`

  return (
    <Layout>
      <SEO title="Blogs" />
      <StyledHero img={data.blogBg.childImageSharp.fluid}/>
      <section className={listStyles.blog}>
        <Title title="Latest" subtitle="Posts"/>
        <div className={listStyles.center}>
          {data.posts.edges.map(({ node }) => {
            return <Post key={node.id} post={node}/>
          })}
        </div>
        <section className={listStyles.links}>
          {!isFirst && (<AniLink fade to={prevPage} className={listStyles.link}>
            Prev
          </AniLink>)}

          {Array.from({ length: numPages }, (_, index) => {
            return <AniLink key={index} fade to={`/blogs/${index === 0 ? "" : index + 1}`}
                            className={index + 1 === currentPage ? `${listStyles.link} ${listStyles.active}` : `${listStyles.link}`}>
              {index + 1}
            </AniLink>
          })}
          {!isLast && (<AniLink fade to={nextPage} className={listStyles.link}>
            Next
          </AniLink>)}
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query getPosts($skip: Int!, $limit: Int!) {
        posts: allContentfulPost(
            skip: $skip, limit: $limit,
            sort: {fields: published, order: DESC}) {
            edges {
                node {
                    slug
                    title
                    id:contentful_id
                    published(formatString: "Do, MMMM Y")
                    image {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
        blogBg:file(relativePath:{eq:"blogBg.jpg"}) {
            childImageSharp {
                fluid(quality:90, maxWidth:4160) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`


export default PostList
