import React from "react"
import postStyles from '../../css/blog-card.module.css'
import Image from 'gatsby-image'
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Post = ({ post }) => {
  const {title, slug, image, published} = post
  return (
    <article className={postStyles.blog}>
      <div className={postStyles.imgContainer}>
        <Image fluid={image.fluid} className={postStyles.img} alt="Single post"/>
        <AniLink fade to={`/blog/${slug}`} className={postStyles.link}>
          Read More
        </AniLink>
        <h6 className={postStyles.date}>{ published }</h6>
      </div>
      <div className={postStyles.footer}>
        <h4>{ title }</h4>
      </div>
    </article>
  )
}

export default Post
